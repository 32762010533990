<template>
  <b-navbar :class="{ 'is-fixed-top': true, 'has-background-danger': devMode }">
    <template slot="brand">
      <b-navbar-item @click.native="toggleSidebar">
        <b-icon icon="angle-double-right" type="is-primary" />
      </b-navbar-item>
    </template>
    <template slot="end">
      <b-navbar-dropdown :right="true">
        <template v-slot:label>
          <span>{{ name }}</span>
        </template>
        <b-navbar-item @click.native="clickLogout">
          <b-icon icon="sign-out-alt" />
          <span>Se déconnecter</span>
        </b-navbar-item>
      </b-navbar-dropdown>
    </template>
  </b-navbar>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'AppNavbar',

  data() {
    return {
      devMode: process.env.NODE_ENV !== 'production',
    }
  },

  computed: {
    ...mapState('auth', ['identity']),

    name() {
      return this.identity?.firstname || ''
    },
  },

  async created() {
    await this.loadIdentity()
  },

  methods: {
    ...mapActions('auth', ['logout', 'loadIdentity']),
    ...mapActions('ui', ['toggleSidebar']),

    clickLogout() {
      this.logout()
      this.$router.push({ name: 'login' })
    },
  },
}
</script>
