import { parseISO } from 'date-fns'

export class QueclinkTrackerConfigService {
  constructor(api) {
    this.api = api
  }

  async getAllByLiveId(liveId) {
    return (await this.api.get(`/lives/${liveId}/queclink_tracker_configs`)).map(this.parse)
  }

  async getAllByIds(ids) {
    return (await this.api.post('/queclink_tracker_configs/', { ids })).map(this.parse)
  }

  async getById(id) {
    return this.parse(await this.api.get(`/queclink_tracker_configs/${id}`))
  }

  async update(queclinkTrackerConfig) {
    return this.parse(
      await this.api.put(
        `/queclink_tracker_configs/${queclinkTrackerConfig.id}`,
        queclinkTrackerConfig,
      ),
    )
  }

  async updateAll(queclinkTrackerConfigs) {
    return (await this.api.put('/queclink_tracker_configs/', { queclinkTrackerConfigs })).map(
      this.parse,
    )
  }

  async resync(id) {
    return this.parse(await this.api.put(`/queclink_trackers/${id}/configs`))
  }

  parse(queclinkTrackerConfig) {
    if (queclinkTrackerConfig.synchronizedAt) {
      queclinkTrackerConfig.synchronizedAt = parseISO(queclinkTrackerConfig.synchronizedAt)
    }

    if (queclinkTrackerConfig.initializedAt) {
      queclinkTrackerConfig.initializedAt = parseISO(queclinkTrackerConfig.initializedAt)
    }

    return queclinkTrackerConfig
  }
}
